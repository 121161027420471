<template>
  <v-container>
    <v-snackbar top center v-model="snackbar">
      {{ $t("your business has been registered succesfully") }}!
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.secondaryColor"
          text
          v-bind="attrs"
          @click="snackbar = false"
          :timeout="timeout"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout row wrap justify-space-between>
      <v-flex xs12 sm6 md6 lg6 class="text-left">
        <v-btn
          small
          outlined
          class="mr-4"
          :color="$store.state.secondaryColor"
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption text-capitalize font-weight-bold">{{
            $t("back")
          }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 class="text-right">
        <v-btn
          small
          outlined
          class="mr-4"
          :color="$store.state.secondaryColor"
          @click="demo"
        >
          <v-icon small left>gamepad</v-icon>
          <span class="caption text-capitalize font-weight-bold">Demo</span>
        </v-btn>
        <v-btn
          small
          dark
          class="gradients"
          :color="$store.state.secondaryColor"
          @click="uploadBusiness"
          :loading="loading"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize font-weight-bold">{{
            $t("register")
          }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-space-between class="mt-3">
      <v-flex xs12 sm6 md6 lg6>
        <h2 class="headline text-capitalize">{{ title }}</h2>
        <small>*{{ subtitle }}</small>
      </v-flex>
    </v-layout>

    <v-alert class="mt-4">
      <v-form ref="form">
        <v-container>
          <v-layout row wrap justify-space-around class="mb-3">
            <v-flex xs12 sm4 md4 lg4 class="text-center">
              <v-card class="pa-2" max-width="344"
                ><v-avatar tile class="profile" size="164">
                  <v-img :src="logoPreview" alt="business logo"></v-img>
                </v-avatar>
              </v-card>
              <v-layout row wrap class="mt-2 mb-2">
                <v-flex xs12 sm12 md12 lg12>
                  <span class="caption">{{ $t("business logo") }} *</span>
                  <input
                    type="file"
                    @change="previewLogo"
                    dense
                    small-chips
                    :rules="rules.emptyField"
                    class="caption mt-2"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4 class="text-center">
              <v-card class="pa-2" max-width="344"
                ><v-avatar tile class="profile" size="164">
                  <v-img :src="bannerPreview" alt="business banner"></v-img>
                </v-avatar>
              </v-card>

              <v-layout row wrap class="mt-2 mb-2">
                <v-flex xs12 sm12 md12 lg12>
                  <span class="caption">{{ $t("business banner") }} *</span>
                  <input
                    type="file"
                    @change="previewBanner"
                    dense
                    small-chips
                    :rules="rules.emptyField"
                    class="caption mt-2"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-1 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("business name") }} * :
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.businessName"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize">{{
                $t("abbreviation")
              }}</span>
              <v-text-field
                outlined
                dense
                v-model="register.abbreviation"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("type of business") }}*</span
              >
              <v-combobox
                v-model="register.businessType"
                :items="businessTypes"
                @change="onSelectBusinessType"
                :rules="rules.emptyField"
                outlined
                dense
              ></v-combobox>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("website") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.website"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mt-4 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("email") }} * :
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.email"
                :rules="rules.email"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("telephone") }}*</span
              >
              <v-text-field
                outlined
                dense
                v-model="register.phone"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("PO BOX") }} *</span
              >
              <v-text-field
                outlined
                dense
                v-model="register.address.pobox"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("registration number") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.regNo"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-4 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("permit number") }} :
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.permitNo"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("pin") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.pin"
              ></v-text-field> </v-flex
            ><v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("bank name") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.bank.bankName"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("bank account number") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.bank.accountNo"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-4 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("mpesa till number") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.mpesa.tillNo"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("mpesa paybill number") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.mpesa.paybillNo"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize">
                {{ $t("mpesa account number") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.mpesa.accountNo"
              ></v-text-field> </v-flex
            ><v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("glopay") }}
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.glopay"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mt-4 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("country") }}*
              </span>
              <v-combobox
                v-model="register.address.country"
                :items="countries"
                @change="selectCountry"
                :rules="rules.emptyField"
                outlined
                dense
              ></v-combobox>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("county-province") }} *</span
              >
              <v-combobox
                outlined
                dense
                v-model="register.address.county"
                :items="provinces"
                @change="selectCounty"
                :rules="rules.emptyField"
              ></v-combobox>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("city-town") }}*</span
              >
              <v-combobox
                outlined
                dense
                :items="towns"
                v-model="register.address.city"
              ></v-combobox>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("district-territory") }}
              </span>
              <v-combobox
                outlined
                dense
                v-model="register.address.territory"
                :items="territories"
              ></v-combobox>
            </v-flex>
          </v-layout>

          <v-layout row wrap class="mt-4 caption elevation-1">
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("estate") }}:
              </span>
              <v-text-field
                outlined
                dense
                v-model="register.address.estate"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("street") }}*</span
              >

              <v-text-field
                outlined
                dense
                v-model="register.address.street"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("building") }}*</span
              >
              <v-text-field
                outlined
                dense
                v-model="register.address.building"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md3 lg3 class="px-1">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("house-suite-number") }}*</span
              >
              <v-text-field
                outlined
                dense
                v-model="register.address.no"
                :rules="rules.emptyField"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-alert>
  </v-container>
</template>

<script>
import imagePreview from "@/assets/blank-profile.svg";
import { format } from "date-fns";
import db from "@/plugins/fb";
import fb from "firebase/app";
import countryList from "@/data/countries";

export default {
  data: () => ({
    imagePreview,
    snackbar: false,
    timeout: 2000,
    loading: false,
    dialog: false,

    register: {
      ownerId: "",
      logo: "",
      banner: "",
      businessName: "",
      abbreviation: "",
      businessType: "",
      regNo: "",
      permitNo: "",
      website: "",
      email: "",
      phone: "",
      address: {
        country: "",
        county: "",
        city: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
      bank: {
        bankName: "",
        accountNo: "",
      },
      mpesa: {
        tillNo: "",
        paybillNo: "",
        accountNo: "",
      },
      glopay: "",
      pin: "",

      verified: false,
      dateCreated: format(new Date(), "yyyy-MM-dd"),
    },

    provinces: [],
    towns: [],

    territories: [],
    countryList,

    logoPreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    bannerPreview:
      " https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",

    logo: "",
    banner: "",
    createdBusiness: "",
  }),

  computed: {
    //fetch the stored business types
    businessTypes() {
      return this.$store.getters.getBusinessTypes;
    },
    countries() {
      return this.countryList.map((item) => item.country);
    },
    rules() {
      return {
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
    title() {
      return this.$t("business registration form");
    },
    subtitle() {
      return this.$t("indicates a required field");
    },
  },

  mounted() {
    this.setOwner();
  },

  methods: {
    setOwner() {
      this.register.ownerId = this.$route.params.id;
    },

    onSelectBusinessType(businessType) {
      this.register.businessType = businessType;
    },

    demo() {
      this.register.businessName = "Rembo Collections";
      this.register.abbreviation = "R.C";
      this.register.businessType = "Clothing Store";
      this.register.regNo = "23456781";
      this.register.permitNo = "2213341111/0000";
      this.register.address.pobox = "11567 – 00100 Nairobi ,Kenya";
      this.register.email = "rc@fashion.com";
      this.register.website = "www.rembocollections.co.ke";
      this.register.phone = "+2540700000000";
      this.register.address.country = "Kenya";
      this.register.address.county = "Nairobi";
      this.register.address.city = "Nairobi";
      this.register.address.street = "Tom Mboya Street";
      this.register.address.estate = "CBD";
      this.register.address.building = "Imenti House";
      this.register.address.no = "Z31";
      this.register.bank.bankName = "Equity Bank - Moi Avenue";
      this.register.bank.accountNo = "22733419";
      this.register.mpesa.tillNo = "010101";
      this.register.mpesa.paybillNo = "090909";
      this.register.mpesa.accountNo = "rm900";
      this.register.glopay = "N/A";
      this.register.pin = "AB00123456";
    },

    previewLogo(event) {
      this.logo = event.target.files[0];
      this.logoPreview = URL.createObjectURL(this.logo);
    },

    previewBanner(event) {
      this.banner = event.target.files[0];
      this.bannerPreview = URL.createObjectURL(this.banner);
    },

    uploadBanner(id) {
      let storageRef = fb
        .storage()
        .ref("businesses/banners/" + this.bannerName);
      let uploadTask = storageRef.put(this.banner);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                banner: downloadLink,
              })
              .then(() => {
                if (this.logo != "") this.uploadLogo(id);
              });
          });
        }
      );
    },

    uploadLogo(id) {
      let storageRef = fb.storage().ref("businesses/logos/" + this.logoName);
      let uploadTask = storageRef.put(this.logo);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                logo: downloadLink,
              })
              .then(() => {
                this.loading = false;
              });
          });
        }
      );
    },

    uploadBusiness() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("businesses")
          .add(this.register)
          .then((snaps) => {
            if (this.banner != "") this.uploadBanner(snaps.id);
            console.log("Document written with ID: ", snaps.id);
            this.loading = false;
            this.snackbar = true;
            setTimeout(() => this.goBack(), 2000);
          });
      }
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.register.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.register.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    goBack() {
      this.$router.push(window.history.back());
    },
  },
};
</script>

<style scoped>
.box {
  border: 1px solid #ef6c00;
}
.title {
  border-left: 3px solid #ef6c00;
}
</style>
